import React, { useState, useEffect } from 'react';


import '../css/styles.css';
import {  Route, Routes, useLocation, useNavigate } from "react-router";
import CountrySelect from './CountrySelect';
import Installation from './Installation';
import Nopagefound from './Nopagefound';
import ReauthSuccessfull from './ReauthSuccessfull';

const Auth = () => {
    const [fulldata, setfulldata] = useState<any>({})
    const location = useLocation();
    const navigate = useNavigate()
    useEffect(() => {
        const param = location.search
        const searchParams = new URLSearchParams(param);
        // Convert search parameters to a JSON object

        let jsonResult: any = {};

        if (searchParams.size > 0) {
            searchParams.forEach((value, key) => {
                jsonResult[key] = value;
            });


            setfulldata(jsonResult);
            if (jsonResult?.newreauth) {
                // setpageLoader(false);
                navigate("configure")
            }
            else if(jsonResult?.reauthSuccess){
                // setpageLoader(false);
                navigate("reauthSuccess")

            }
            else {
                navigate("install")
            }

        }
    }, [location, navigate])


    return (
        <Routes>
            <Route path="/install" element={<Installation jsonResult={fulldata} />} />
            <Route path="/configure" element={<CountrySelect jsonResult={fulldata} />} />
            <Route path="/reauthSuccess" element={<ReauthSuccessfull jsonResult={fulldata} />} />
            <Route path="*" element={<Nopagefound/>}/>
        </Routes>
    );
}


export default Auth;