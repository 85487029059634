import React from 'react';
import './App.css';
import Auth from './components/Modal/components/Modal';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CountrySelect from './components/Modal/components/CountrySelect';
import Installation from './components/Modal/components/Installation';

function App() {
  return (

     <Auth/>

  );
}

export default App;
