import { FlexLayout, TextStyles, Card, FlexChild, Button, Modal, TextField} from '@cedcommerce-integration/ounce-ui';
import React, { useEffect, useState } from 'react'
import { ArrowRight } from 'react-feather';
import { CedcommerceLogo, ShopifyLogo, ShoplineLogo, WooCommerceLogo } from '../../../assets/Svg';
import jwt_decode from "jwt-decode";

function Installation({ jsonResult, fulldata }: any) {
    const [setLoginLoading, setsetLoginLoading] = useState<boolean>(false)
    const [modalbuttonloading, setmodalbuttonloading] = useState<boolean>(false)
    const [loading, setloading] = useState<boolean>(false);

    const [ErrorMessage, setErrorMessage] = useState('')
    const [storeUrl, setstoreUrl] = useState<string>("")
    const [open2, toggleModal2] = useState(true);
    const [shopName, setshopName] = useState<any>({})
    const [selected, setselected] = useState("")


    const [buttonName, setbuttonName] = useState<string>("connect");
    const [decodedToken, setdecodedToken] = useState<any>({})

    useEffect(() => {
        let decoded = jsonResult['data'] ? jwt_decode(jsonResult['data']) : null;

        setdecodedToken(decoded)
    }, [jsonResult])





    const logintostore = () => {
        setsetLoginLoading(true);
        if (shopName.length > 0) {
            // Regular expression pattern to match Shopify shop URLs
            const regex: any = /^(https?:\/\/)?([a-zA-Z0-9-]+)\.myshopify\.com$/i;
            // console.log(process.env.REACT_APP_BASE_URL+`connector/request/shopifyCurrentRoute?current_route=dashboard&sAppId=1&shop=${selectedUrl}`)
            window.open(`https://admin.shopify.com/store/${regex.exec(shopName[0]["username"])[2]}/apps/amazon-by-cedcommerce-dev-1`, "_self")
        }
    }
    const handleOpenWindow = () => {
        window.open("https://accounts.shopify.com/", "_self", "width=800,height=600")
    }


    const selectMarketplace = (data: string) => {

        Object.keys(decodedToken).length > 0 && setselected(data);
        Object.keys(decodedToken).length > 0 && setloading(true);
        Object.keys(decodedToken).length > 0 && fetch(process.env.REACT_APP_BASE_URL + "shopifyhome/installation/checkForShop", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`
            },
            body: JSON.stringify({
                "remote_shop_id": decodedToken?.data?.shop_id,
            })
        }).then(res => res.json()).then(data => {
            setloading(false)

            if (data?.success) {
                setshopName(data?.shops);
                setbuttonName("Re-Authenticate")
            }
            else {
                console.error(data?.message)
            }
        }
        ).catch(err => console.error(err))
    }



    const handleConnectAction = () => {
        toggleModal2(!open2);
    }
    const primaryAction2 =
    {
        loading: modalbuttonloading,
        content: buttonName,
        onClick: async () => {
            setmodalbuttonloading(true);
            setErrorMessage('');
            await fetch(process.env.REACT_APP_BASE_URL + "shopifyhome/installation/install", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`
                },
                body: JSON.stringify({ "tokenData": decodedToken?.data, "shopUrl": storeUrl + ".myshopify.com", "fullremoteData": fulldata })
            }).then(res => res.json()).then(data => {

                if (data?.success) {
                    window.open(process.env.REACT_APP_BASE_URL + data?.url, "_self")
                }
                else {
                    setmodalbuttonloading(false)
                    console.error(data?.message)
                }
            }
            ).catch(err => console.error(err))


        },
    };
    const secondaryAction2 = {
        content: "Cancel",
        loading: false,
        onClick: () => {
            toggleModal2(!open2);
        },
    };

    return (

        <div className="init-Login__Wrapper">
            <div className="init-LoginPage">
                <div className="inte-auth-custom">
                    <FlexLayout direction="vertical" spacing="extraLoose">
                        <FlexLayout valign="start" spacing="loose" wrap="noWrap">
                            <CedcommerceLogo />
                            <FlexLayout direction="vertical">
                                <TextStyles
                                    alignment="left"
                                    fontweight="extraBold"
                                    headingTypes="LG-2.8"
                                    lineHeight="LH-4.0"
                                    textcolor="dark"
                                    type="Heading"
                                >
                                    Welcome to CedCommerce Amazon Integrations
                                </TextStyles>
                                <TextStyles
                                    type="SubHeading"
                                    alignment="left"
                                    lineHeight="LH-4.0"
                                    utility="inte__Heading-font--xxl app-subtitle"
                                    textcolor='light'
                                >
                                    By CedCommerce
                                </TextStyles>
                            </FlexLayout>
                        </FlexLayout>

                        <FlexLayout direction="vertical" spacing="tight">
                            <Card
                                cardType="Shadowed"
                                onError={function noRefCheck() { }}
                                onLoad={function noRefCheck() { }}
                                primaryAction={selected && Object.keys(shopName).length === 0 ? {
                                    content: 'New Account',
                                    type: 'Primary',
                                    icon: <ArrowRight size={16} />,
                                    // disable: !(Object.keys(shopName).length === 0  selected.length === 0),
                                    iconAlign: "right",
                                    onClick: handleConnectAction,
                                } : undefined}
                                subTitle="Please select at least one channel to connect with your Amazon account to complete the onboarding process."
                                title="Multiple Amazon Account Connect"
                            >
                                <FlexLayout spacing="loose">
                                    <FlexChild desktopWidth='33' tabWidth='33' mobileWidth='100'>
                                        <span onClick={() => selectMarketplace("shopify")}>
                                            <Card cardType='Bordered' extraClass={selected === "shopify" ? "Custom__Card--Logo-Border" : "Custom__Card--Logo"}>
                                                <ShopifyLogo />
                                            </Card>
                                        </span>
                                    </FlexChild>

                                    <FlexChild desktopWidth='33' tabWidth='33' mobileWidth='100'>
                                        {/* <span onClick={() => { console.log("clicked shopline"); setselected("shopline") }}> */}
                                        <Card cardType='Bordered' extraClass={"disable"}>
                                            <ShoplineLogo />
                                        </Card>
                                        {/* </span> */}
                                    </FlexChild>
                                    <FlexChild desktopWidth='33' tabWidth='33' mobileWidth='100'>
                                        {/* <span onClick={() => { console.log("clicked shopline"); setselected("shopline") }}> */}
                                        <Card cardType='Bordered' extraClass={"disable"}>
                                            <WooCommerceLogo />
                                        </Card>
                                        {/* </span> */}
                                    </FlexChild>
                                </FlexLayout >
                                {
                                    Object.keys(shopName).length > 0 && <div style={{ marginTop: "20px" }}><FlexLayout direction="vertical" spacing="mediumTight">
                                        <TextStyles fontweight="bold" >We have found your already connected Store, please login to your store to proceed:</TextStyles>
                                        {Object.values(shopName).map((value: any) => {
                                            return (
                                                <FlexLayout halign='fill' spacing='extraLoose' key={value?.username}>
                                                    <TextStyles type="SubHeading" textcolor="positive" children={value?.username} ></TextStyles>
                                                    <Button loading={setLoginLoading} onClick={logintostore}>Login</Button>
                                                </FlexLayout>
                                            )
                                        }
                                        )}
                                    </FlexLayout></div>
                                }

                            </Card >
                        </FlexLayout >
                    </FlexLayout >
                    <Modal
                        modalSize="medium"
                        open={!open2}
                        heading={"Connect Shopify Account"}
                        primaryAction={primaryAction2}
                        secondaryAction={secondaryAction2}
                        close={() => {
                            toggleModal2(!open2);
                        }}
                    >
                        <FlexLayout direction='vertical' spacing='loose'>
                            <TextField
                                name="Store name"
                                placeHolder="Enter shopify store name here"
                                suffix={".myshopify.com"}
                                required
                                value={storeUrl}
                                onChange={(value: string) => setstoreUrl(value)}
                                tabIndex={1}
                                thickness="thick"
                                type="text"
                            />

                            {ErrorMessage || <TextStyles textcolor='negative' >{ErrorMessage}</TextStyles>}
                            <FlexLayout spacing='extraTight'>
                                <TextStyles
                                    fontweight="normal"
                                    paragraphTypes="MD-1.4"
                                    textcolor="light"
                                    type="Paragraph"
                                >
                                    New to Shopify?
                                </TextStyles>
                                <Button
                                    content="Create an account"
                                    onAction={function noRefCheck() { }}
                                    onClick={() => handleOpenWindow()}
                                    thickness="thin"
                                    type="TextButton"
                                />
                            </FlexLayout>
                        </FlexLayout>
                    </Modal>

                </div >
                {/* {toast && fulldata.success !== "0" && <ToastWrapper>
                    <Toast
                        message="your amazon account is sucessfully connected"
                        onDismiss={() => settoast(false)}
                        timeout={2000}
                        type="success"
                    />
                </ToastWrapper>} */}
            </div >


        </div >


    )
}

export default Installation