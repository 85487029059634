import { FlexLayout } from '@cedcommerce-integration/ounce-ui';
import React from 'react'
import { CedcommerceLogo } from '../../../assets/Svg';
// import Successssss from "../../../assets/Success.jpg"

function ReauthSuccessfull(Props: any) {
    const { jsonResult } = Props;
    console.log(jsonResult?.platforms, "dxfcgvhbjnk")
    return (
        <div className="init-Login__Wrapper">
            <div className="init-LoginPage ">
                <FlexLayout valign='center' spacing='loose' halign='center' >
                    <svg width="100" height="100" viewBox="0 0 100 100">
                        <polyline points="20,50 40,70 70,30" stroke="black" stroke-width="10" fill="none" />
                    </svg>
                    <>
                        <FlexLayout direction='vertical' spacing='loose'>
                            <FlexLayout direction='vertical' >
                                <h1>Congratulations , your authentication is successfull.</h1>
                                <p>You can go to your App and start using it</p>
                            </FlexLayout>
                            <p>{jsonResult?.platforms ? <p><b>Your Platforms/Carts : </b>{` ${jsonResult.platforms}`}</p> : null}</p>
                        </FlexLayout>

                    </>


                </FlexLayout>



            </div >


        </div >
    )
}

export default ReauthSuccessfull
