import { FlexLayout, TextStyles, Card, Select } from '@cedcommerce-integration/ounce-ui'
import React, {  useState } from 'react'
import { CedcommerceLogo } from '../../../assets/Svg'
import { ArrowRight } from 'react-feather';

function CountrySelect({ jsonResult }: any) {
    const [country, setcountry] = useState<any>({})
    const option = [
        { value: "europe", label: "Europe", code: "EU", marketplace_id: '', disabled: true },
        {
            label: "United Arab Emirates (U.A.E.)",
            value: "AE",
            marketplace_id: "A2VIGQ35RCS4UG",
            region: "EU",
            currency: "AED",
            url: "Amazon.ae",
            currency_symbol: 'AED '
        },
        {
            label: "Belgium",
            value: "BE",
            marketplace_id: "AMEN7PMS3EDWL",
            region: "EU",
            currency: "Euro",
            url: "Amazon.com.be",
            currency_symbol: 'Euro '
        },
        {
            label: "Germany",
            value: "DE",
            marketplace_id: "A1PA6795UKMFR9",
            region: "EU",
            currency: "EUR",
            url: "Amazon.de",
            currency_symbol: '€ '
        },
        {
            label: "Spain",
            value: "ES",
            marketplace_id: "A1RKKUPIHCS9HS",
            region: "EU",
            currency: "EUR",
            url: "Amazon.es",
            currency_symbol: '€ '
        },
        {
            label: "Saudi Arabia",
            value: "SA",
            marketplace_id: "A17E79C6D8DWNP",
            region: "EU",
            currency: "Saudi Riyal",
            url: "Amazon.sa",
            currency_symbol: 'Saudi Riyal '
        },
        {
            label: "Egypt",
            value: "EG",
            marketplace_id: "ARBP9OOSHTCHU",
            region: "EU",
            currency: "Egyptian Pound",
            url: "Amazon.eg",
            currency_symbol: 'E£'
        },
        {
            label: "Turkey",
            value: "TR",
            marketplace_id: "A33AVAJ2PDY3EV",
            region: "EU",
            currency: "Turkish Lira",
            url: "Amazon.com.tr",
            currency_symbol: '₺'
        },
        {
            label: "France",
            value: "FR",
            marketplace_id: "A13V1IB3VIYZZH",
            region: "EU",
            currency: "EUR",
            url: "Amazon.fr",
            currency_symbol: '€ '
        },
        {
            label: "UK",
            value: "GB",
            marketplace_id: "A1F83G8C2ARO7P",
            region: "EU",
            currency: "GBP",
            url: "Amazon.co.uk",
            currency_symbol: '£ '
        },
        {
            label: "India",
            value: "IN",
            marketplace_id: "A21TJRUUN4KGV",
            region: "EU",
            currency: "INR",
            url: "Amazon.in",
            currency_symbol: '₹ '
        },
        {
            label: "Italy",
            value: "IT",
            marketplace_id: "APJ6JRA9NG5V4",
            region: "EU",
            currency: "EUR",
            url: "Amazon.it",
            currency_symbol: '€ '
        },
        {
            label: "Netherlands",
            value: "NL",
            marketplace_id: "A1805IZSGTT6HS",
            region: "EU",
            currency: "EUR",
            url: "Amazon.nl",
            currency_symbol: '€ '
        },
        {
            label: "Poland",
            value: "PO",
            marketplace_id: "A1C3SOZRARQ6R3",
            region: "EU",
            currency: "PLN",
            url: "Amazon.pl",
            currency_symbol: 'zł '
        },
        {
            label: "Sweden",
            value: "SE",
            marketplace_id: "A2NODRKZP88ZB9",
            region: "EU",
            currency: "SEK",
            url: "Amazon.se",
            currency_symbol: 'kr '
        },
        { value: "far_east", label: "Far East", code: "FE", marketplace_id: '', disabled: true },
        {
            label: "Singapore",
            value: "SG",
            marketplace_id: "A19VAU5U5O7RUS",
            region: "FE",
            currency: "SGD",
            url: "Amazon.com.sg",
            currency_symbol: 'S$ '
        },
        {
            label: "Australia",
            value: "AU",
            marketplace_id: "A39IBJ37TRP1C6",
            region: "FE",
            currency: "AUD",
            url: "Amazon.com.au",
            currency_symbol: '$ '
        },
        {
            label: "Japan",
            value: "JP",
            marketplace_id: "A1VC38T7YXB528",
            region: "FE",
            currency: "JPY",
            url: "Amazon.co.jp",
            currency_symbol: '¥ '
        },
        { value: "north_america", label: "North America", code: "NA", marketplace_id: '', disabled: true },
        {
            label: "Brazil",
            value: "BR",
            marketplace_id: "A2Q3Y263D00KWC",
            region: "NA",
            currency: "BRL",
            url: "Amazon.com.br",
            currency_symbol: 'R$ '
        },
        {
            label: "Canada",
            value: "CA",
            marketplace_id: "A2EUQ1WTGCTBG2",
            region: "NA",
            currency: "CAD",
            url: "Amazon.ca",
            currency_symbol: '$ '
        },
        {
            label: "Mexico",
            value: "MX",
            marketplace_id: "A1AM78C64UM0Y8",
            region: "NA",
            currency: "MXN",
            url: "Amazon.com.mx",
            currency_symbol: '$ '
        },
        {
            label: "US",
            value: "US",
            marketplace_id: "ATVPDKIKX0DER",
            region: "NA",
            currency: "USD",
            url: "Amazon.com",
            currency_symbol: '$ '
        }
    ]










    const handleconnect = async() => {

        let newState: any={...jsonResult}
        newState['region'] = country?.region
        newState['marketplace_id'] = country?.marketplace_id
        const queryString:any = new URLSearchParams(newState).toString()
        console.log(process.env.REACT_APP_BASE_URL_REMOTE+"apiconnect/request/commenceAuth/?"+queryString,"dfghjk")
        window.open(process.env.REACT_APP_BASE_URL_REMOTE+"apiconnect/request/commenceAuth/?"+queryString,"_self")


    }
    return (

            <div className="init-Login__Wrapper">
                <div className="init-LoginPage">
                    <div className="inte-auth-custom">
                        <FlexLayout direction="vertical" spacing="extraLoose">
                            <FlexLayout valign="start" spacing="loose" wrap="noWrap">
                                <CedcommerceLogo />
                                <FlexLayout direction="vertical">
                                    <TextStyles
                                        alignment="left"
                                        fontweight="extraBold"
                                        headingTypes="LG-2.8"
                                        lineHeight="LH-4.0"
                                        textcolor="dark"
                                        type="Heading"
                                    >
                                        Welcome to CedCommerce Amazon Integrations
                                    </TextStyles>
                                    <TextStyles
                                        type="SubHeading"
                                        alignment="left"
                                        lineHeight="LH-4.0"
                                        utility="inte__Heading-font--xxl app-subtitle"
                                        textcolor='light'
                                    >
                                        By CedCommerce
                                    </TextStyles>
                                </FlexLayout>
                            </FlexLayout>

                            <FlexLayout direction="vertical" spacing="tight">
                                <Card
                                    cardType="Shadowed"
                                    onError={function noRefCheck() { }}
                                    onLoad={function noRefCheck() { }}
                                    primaryAction={{
                                        content: 'New Account',
                                        type: 'Primary',
                                        icon: <ArrowRight size={16} />,
                                        disable: country.length === 0,
                                        iconAlign: "right",
                                        onClick: handleconnect,
                                    }}
                                    subTitle="Pick the country where you're selling in and connect your Amazon account for that country. "
                                    title="Multiple Amazon Account Connect"
                                >
                                    <Select
                                        helpIcon={<svg fill="none" height="20" stroke="#c3c3c3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="8" /><line x1="21" x2="16.65" y1="21" y2="16.65" /></svg>}
                                        name="Select"
                                        onChange={(e, obj) => setcountry(obj)}
                                        onblur={function noRefCheck() { }}
                                        options={option}
                                        searchEable
                                        value={country?.value}
                                    />


                                </Card >
                            </FlexLayout >
                        </FlexLayout >


                    </div >
                </div >


            </div >


    )
}

export default CountrySelect