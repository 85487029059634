import { Card, FlexLayout, Image } from '@cedcommerce-integration/ounce-ui';
import broken from '../../../assets/404.jpg';

function Nopagefound() {
    return (
        <div className="init-Login__Wrapper">
            <div className="init-LoginPage custom-404">
                <Card cardType='Plain'>
                    <FlexLayout valign='center' spacing='loose' halign='center' >
                        <Image src={broken} height={320} width={320}></Image>
                        <>
                            <h1>Oops! Page not found.</h1>
                            <p>It seems you've landed on a path that doesn't exist.</p>
                        </>
                    </FlexLayout>
                    {/* <img src={CedcommerceLogo} alt="Page Not Found" /> */}
                </Card>


            </div >


        </div >
    )
}

export default Nopagefound
